<template>
  <div>
    <b-dropdown-item v-b-modal="'fetch-transfer-licenses-modal'">VIP-Nr. abfragen</b-dropdown-item>
    <b-modal 
      id="fetch-transfer-licenses-modal" :content-class="contentClass"
      hide-footer
      :body-class="bodyClass"
      :header-class="headerClass"
      @hidden="reset()"
      size="lg">
      <template #modal-title>
        Lizenzen zum Transferieren abfragen
      </template>
      <template v-if="!result">
        <b-form-group>
          <label>VIP-Nr.</label>
          <b-input v-model="vipNr"></b-input>
        </b-form-group>
        <div class="d-flex flex-end pt-3">
          <b-button v-on:click="submit()">Lizenzen abfragen</b-button>
        </div>
      </template>
      <template v-else-if="result == 'Loading'">
        <div class="d-flex justify-content-center align-items-center bg-white my-5">
          <b-spinner label="Spinning" variant="secondary"></b-spinner>
        </div>
      </template>
      <template v-else>
        <b-table :items="result.data.items" :fields="fields" class="mb-0" sort-icon-left>
          <template #cell(offerId)="data">
            {{ getProductName(data.item) }}<br>
            <snall>{{data.item.offerId}}</snall>
          </template>
          <template #cell(renewalDate)="data">
            {{ data.item.renewalDate | date }}
          </template>
        </b-table>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "FetchTransferLicensesComponent",
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      cotermDate: moment().format("YYYY-MM-DD"),
      vipNr: "",
      result: null,
      products: [],
      fields: [
        {
          key: "offerId",
          label: "Productname",
          sortable: false,
        },
        {
          key: "renewalDate",
          label: "Renewal Date",
          sortable: false,
        },
        {
          key: "quantity",
          label: "Menge",
          sortable: false,
        },
      ]
    }
  },
  methods: {
    async submit() {
      if (this.vipNr === "") {
        return;
      }
      this.result = 'Loading'

      const FetchTransferLicenses = httpsCallable(functions, "dashboard-checkTransferLicenses");
      await FetchTransferLicenses({
        vipNr: this.vipNr,
      })
        .then((res) => {
          this.result = res;
        })
        .catch((error) => {
          this.reset();
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
    },
    async getProducts() {
      const colRef = collection(firestore, "products");
      const products = await getDocs(colRef);

      if (products.empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        return;
      }

      products.forEach((product) => {
        this.products.push(product.data());
      });
    },
    getProductName(item) {
      const matchingProducts = this.products.filter((product) => item.offerId === product.manufacturerItemNumber);
      if (matchingProducts.length > 0) {
        return matchingProducts[0].productName;
      } else {
        return item.offerId;
      }
    },
    reset() {
      this.result = null;
      this.vipNr = "";
    },
  },
  mounted() {
    this.getProducts();
  },
}
</script>