import Vue from "vue";
import App from "./App.vue";

import router from "./plugins/router";

import store from "./plugins/store";

import "./plugins/fontawesome";

import "./plugins/apexCharts";

import { onAuthStateChanged, getAuth } from "firebase/auth";

import {
  dateTime,
  orderStatus,
  currency,
  date,
  dateShort,
  accountStatus,
  time,
  currencyWithNoFractions,
  productNameWithoutLevel,
} from "./plugins/filters";

import { BootstrapVue } from "bootstrap-vue";
import "@comlinedev/bootstrap4/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

let app;
onAuthStateChanged(getAuth(), () => {
  if (!app) {
    Vue.filter("dateTime", dateTime);
    Vue.filter("orderStatus", orderStatus);
    Vue.filter("currency", currency);
    Vue.filter("date", date);
    Vue.filter("dateShort", dateShort);
    Vue.filter("accountStatus", accountStatus);
    Vue.filter("time", time);
    Vue.filter("currencyWithNoFractions", currencyWithNoFractions);
    Vue.filter("productNameWithoutLevel", productNameWithoutLevel);
    
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
